import { updateMeta } from 'Store/Meta/Meta.action';
import { ___ } from 'Util/Format';
import { appendWithStoreCode } from 'Util/Url';

/**
 * Meta Dispatcher
 * @class MetaDispatcher
 * @namespace Theme/Store/Meta/Dispatcher */
export class MetaDispatcher {
    /**
     * Set meta for category
     * @param {Object} category
     * @param {Function} dispatch
     * @memberof MetaDispatcher
     */
    updateWithCategory(category, dispatch) {
        const meta = this._getCategoryMeta(category);
        dispatch(updateMeta(meta));
    }

    /**
     * Set meta for product
     * @param {Object} product
     * @param {Function} dispatch
     * @memberof MetaDispatcher
     */
    updateWithProduct(product, dispatch) {
        const meta = this._getProductMeta(product);
        dispatch(updateMeta(meta));
    }

    /**
     * Get meta for product
     * @param {Object} product
     * @return {Object} Meta object
     * @memberof MetaDispatcher
     */
    _getProductMeta(product) {
        const {
            name,
            meta_title,
            meta_keyword,
            canonical_url,
            meta_description,
            hide_from_seo = false,
        } = product;

        return {
            description: meta_description,
            keywords: meta_keyword,
            title: ___(meta_title || name),
            canonical_url: `${window.location.origin}${appendWithStoreCode(canonical_url)}`,
            robots: hide_from_seo ? 'nofollow, noindex' : null,
        };
    }

    /**
     * Get meta for category
     * @param {Object} category
     * @return {Object} Meta object
     * @memberof MetaDispatcher
     */
    _getCategoryMeta(category) {
        const {
            description, name, canonical_url,
            meta_title, meta_keywords, meta_description,
            meta_robots = null,
            hide_from_seo,
        } = category;

        return {
            description: meta_description || description,
            title: ___(meta_title || name),
            keywords: meta_keywords,
            canonical_url: `${window.location.origin}${appendWithStoreCode(canonical_url)}`,
            robots: hide_from_seo ? 'nofollow, noindex' : meta_robots,
        };
    }
}

export default new MetaDispatcher();
