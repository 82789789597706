import Image from 'Component/Image';
import Link from 'Component/Link';
import TextPlaceholder from 'Component/TextPlaceholder';
import {
    ProductCardComponent as SourceProductCard,
} from 'SourceComponent/ProductCard/ProductCard.component';
import { ___ } from 'Util/Format';

/** @namespace Theme/Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCard {
    renderPicture(mix = {}) {
        const { product: { id, name }, thumbnail } = this.props;

        this.sharedComponent = (
            <Image
              imageRef={ this.imageRef }
              src={ thumbnail }
              alt={ ___(name) }
              ratio="custom"
              mix={ { block: 'ProductCard', elem: 'Picture', mix } }
              isPlaceholder={ !id }
            />
        );

        return (
            <>
                { this.sharedComponent }
                <img
                  style={ { display: 'none' } }
                  alt={ ___(name) }
                  src={ thumbnail }
                />
            </>
        );
    }

    renderMainDetails() {
        const {
            product: { name },
        } = this.props;

        return (
            <p
              block="ProductCard"
              elem="Name"
              mods={ { isLoaded: !!name } }
            >
                <TextPlaceholder content={ ___(name) } length="medium" />
            </p>
        );
    }

    renderCardLinkWrapper(children, mix = {}) {
        const { linkTo, product: { url, name } } = this.props;

        if (!url) {
            return (
                <div
                  block="ProductCard"
                  elem="Link"
                >
                    { children }
                </div>
            );
        }

        return (
            <Link
              block="ProductCard"
              elem="Link"
              title={ ___(name) }
              to={ linkTo }
              onClick={ this.registerSharedElement }
              mix={ mix }
            >
                { children }
            </Link>
        );
    }

    renderAddToCart() {
        return (
            <button
              block="ProductCard"
              elem="Button"
              mix={ {
                  block: 'Button',
                  mods: { isHollow: true },
              } }
            >
                { __('View product') }
            </button>
        );
    }

    renderCardContent() {
        const { chameleon } = this.props.product;
        const shouldShowPrice = chameleon?.product_price_display;

        return (
            this.renderCardLinkWrapper((
                <>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                        </figure>
                    </div>
                    <div block="ProductCard" elem="Content">
                        { this.renderMainDetails() }
                        { shouldShowPrice && this.renderPrice() }
                    </div>
                    <div block="ProductCard" elem="ViewProduct">
                        { this.renderAddToCart() }
                    </div>
                </>
            ))
        );
    }
}

export default ProductCardComponent;
