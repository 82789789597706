import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { LOADING_TIME } from 'Route/CategoryPage/CategoryPage.config';
import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductPageContainer as SourceProductPageContainer,
} from 'SourceRoute/ProductPage/ProductPage.container';
import ProductReducer from 'Store/Product/Product.reducer';
import { withReducers } from 'Util/DynamicReducer';
import history from 'Util/History';
import { debounce } from 'Util/Request/Debounce';

export { mapDispatchToProps };

/** @namespace Theme/Route/ProductPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isSignedIn: state.MyAccountReducer.isSignedIn,
});

/** @namespace Theme/Route/ProductPage/Container */
export class ProductPageContainer extends SourceProductPageContainer {
    componentDidUpdate(prevProps) {
        const {
            isOffline,
            productSKU,
            product,
            isSignedIn,
        } = this.props;

        const {
            productSKU: prevProductSKU,
            product: prevProduct,
            isSignedIn: prevIsSignedIn,
        } = prevProps;

        const { sku: stateSKU } = history?.location?.state?.product || {};

        if (isOffline) {
            debounce(this.setOfflineNoticeSize, LOADING_TIME)();
        }

        if (isSignedIn !== prevIsSignedIn) {
            this.requestProduct();
        }

        /**
         * We should also update product based data if, the URL
         * rewrite SKU has changed to matching the product history SKU
         * one. At this point there could be sufficient data for
         * some updates (i.e. header state).
         */
        if (
            productSKU !== prevProductSKU
            && stateSKU === productSKU
        ) {
            this.updateHeaderState();
        }

        /**
         * If product object was changed => it is loaded => we need to
         * update product specific information, i.e. breadcrumbs.
         */
        if (JSON.stringify(product) !== JSON.stringify(prevProduct)) {
            this.redirectToCorrectUrl();
            this.updateBreadcrumbs();
            this.updateHeaderState();
            this.updateMeta();
        }

        this._addToRecentlyViewedProducts();
    }

    redirectToCorrectUrl() {
        const { location, history, product } = this.props;

        if (location.pathname.endsWith('.asp')) {
            history.replace(
                product.url,
                history.state,
            );
        }
    }
}

export default withReducers({
    ProductReducer,
})(withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ProductPageContainer),
));
