import {
    ProductPriceComponent as SourceProductPrice,
} from 'SourceComponent/ProductPrice/ProductPrice.component';

import './ProductPrice.style';

/** @namespace Theme/Component/ProductPrice/Component */
export class ProductPriceComponent extends SourceProductPrice {
    renderTierPrice() {
        return null;
    }
}

export default ProductPriceComponent;
